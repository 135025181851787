import React, { useEffect } from "react";
import useStore from "../state/store";
import { Card, CardContent, Typography, Icon } from "@mui/material";
import { CheckCircle, Warning } from "@mui/icons-material";
import Authorities from "./Authorities";
import Evidence from "./Evidence";
import Loading from "./Loading";
import { Link } from "react-router-dom";

const ResultsPage = () => {
  const data = useStore((state) => state.data);
  const error = useStore((state) => state.error);
  const fetchData = useStore((state) => state.fetchData);
  const topic = useStore((state) => state.topic);
  const authorities = useStore((state) => state.authorities);

  useEffect(() => {
    fetchData();
  }, []);

  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <Card>
        <CardContent>
          <Typography
            variant="h5"
            component="div"
            gutterBottom
            style={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            Assertion: {topic}
            {data ? (
              data.agree ? (
                <Icon
                  color="success"
                  component={CheckCircle}
                  fontSize="large"
                />
              ) : (
                <Icon color="warning" component={Warning} fontSize="large" />
              )
            ) : null}
          </Typography>

          {data ? (
            data.evaluated ? (
              data.is_assertion ? (
                <>
                  <Typography variant="body2" gutterBottom>
                    {data.agree
                      ? "Congrats, Scout agrees with you!"
                      : "Alas, Scout does not agree with your assertion.  That's ok, though!  Scout has been know to make mistakes... from time to time."}
                  </Typography>
                  <Typography variant="body2">{data.reasoning}</Typography>
                </>
              ) : (
                <>
                  <Typography variant="body2">{data.reasoning}</Typography>
                  <Typography variant="body2">
                    <Link to="/">Try Again</Link>
                  </Typography>
                </>
              )
            ) : (
              <Loading message="Scout is researching your claim..." />
            )
          ) : (
            <Loading message="Scout is researching your claim..." />
          )}
        </CardContent>
      </Card>
      {data?.is_assertion && <Authorities />}
      {authorities && authorities.length && <Evidence />}
    </div>
  );
};

export default ResultsPage;
