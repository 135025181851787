import React, { useEffect } from 'react';
import useStore from '../state/store';
import {
  List,
  ListItem,
  ListItemText,
  Link,
  Typography,
  Card,
  CardContent,
  Divider,
  Avatar,
} from '@mui/material';
import Loading from './Loading';

const Authorities = () => {
  const authorities = useStore((state) => state.authorities);
  const error = useStore((state) => state.error);
  const getAuthorities = useStore((state) => state.getAuthorities);

  useEffect(() => {
    getAuthorities();
  }, []);

  if (error) return <p>Error: {error}</p>;
  if (!authorities || authorities.length === 0) return <Loading message="Finding subject matter experts..." />;

  return (
    <Card elevation={3} sx={{ mt: 2, p: 1 }}>
      <CardContent sx={{ pt: 1, pb: 1 }}>
        <Typography variant="subtitle1" gutterBottom>
          Suggested Subject Matter Experts
        </Typography>
        <Typography variant="body2" gutterBottom>
          Scout thinks these entities are a good place to start looking for evidence on the subject:
        </Typography>
        <List dense>
          {authorities.map((authority, index) => (
            <React.Fragment key={index}>
              <ListItem disablePadding sx={{ alignItems: 'flex-start' }}>
                <Avatar alt={authority.name} src={authority.image} sx={{ mr: 1, width: 48, height: 48 }} />
                <ListItemText
                  primary={
                    <Typography variant="body2" color="primary">
                      <Link href={authority.url} target="_blank" rel="noopener noreferrer" variant="inherit">
                        {authority.name}
                      </Link>
                    </Typography>
                  }
                  secondary={
                    <Typography variant="caption" color="textSecondary">
                      {authority.reasoning}
                    </Typography>
                  }
                />
              </ListItem>
              {index !== authorities.length - 1 && <Divider variant="middle" />}
            </React.Fragment>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default Authorities;
