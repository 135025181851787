import React from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import useTopicStore from '../state/store';

const SearchBar = ({ onSearch }) => {

  const query = useTopicStore((state) => state.topic);
  const setTopic = useTopicStore((state) => state.setTopic);

  const handleChange = (event) => {
    setTopic(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();  // Prevents the page from reloading when submitting the form
    if(query.trim()) {
      onSearch(query);
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        label="Search"
        variant="outlined"
        value={query}
        onChange={handleChange} 
        fullWidth
      />
      <IconButton type="submit">
        <SearchIcon />
      </IconButton>
    </form>
  );
};

export default SearchBar;
