import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const Navbar = () => {

    return (
        <AppBar position="static">
        <Toolbar>
        <Typography
          variant="h6"
          component={Link}
          to="/"
          sx={{
            flexGrow: 1,
            fontFamily: "'Roboto', sans-serif",
            fontSize: '24px',
            fontWeight: 'bold',
            color: 'white',
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          ScoutBot.ai - Make Your Case
        </Typography>
            <Button color="inherit" component={Link} to="/">Search</Button>
            <Button color="inherit" component={Link} to="/about">About</Button>
        </Toolbar>
        </AppBar>
    );
};

export default Navbar;
