import React, { useEffect } from 'react';
import useStore from '../state/store';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Card,
  CardContent,
  Divider,
  Link,
} from '@mui/material';
import Loading from './Loading';

const Evidence = () => {
  const evidence = useStore((state) => state.evidence);
  const error = useStore((state) => state.error);
  const getEvidence = useStore((state) => state.getEvidence);

  useEffect(() => {
    getEvidence();
  }, []);

  if (error) return <p>Error: {error}</p>;
  if (!evidence || evidence.length === 0) return <Loading message="Researching..." />;

  return (
    <Card elevation={3} sx={{ mt: 2, p: 1 }}>
      <CardContent sx={{ pt: 1, pb: 1 }}>
        <Typography variant="subtitle1" gutterBottom>
          Evidence Found:
        </Typography>
        <List dense>
          {evidence.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem disablePadding sx={{ alignItems: 'flex-start' }}>
                <img src={item.image} alt={`Image for ${item.title}`} style={{ marginRight: '16px', width: '100px', height: 'auto' }} />
                <ListItemText
                  primary={
                    <Link href={item.link} target="_blank" rel="noopener noreferrer" variant="body2">
                      {item.title}
                    </Link>
                  }
                  secondary={
                    <Typography variant="caption" color="textSecondary">
                      {item.snippet}
                    </Typography>
                  }
                />
              </ListItem>
              {index !== evidence.length - 1 && <Divider variant="middle" />}
            </React.Fragment>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default Evidence;
