import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/system';

const AboutContainer = styled(Paper)(({ theme }) => ({
  maxWidth: 800,
  margin: 'auto',
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
}));

const About = () => {
  return (
    <AboutContainer elevation={3}>
      <Typography variant="h4" gutterBottom>
        About Scout
      </Typography>
      <List>
      <ListItem>
          <ListItemText 
            primary="Scout dislikes disinformation and works hard to help you clear things up." 
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary="Scout is the bastard lovechild of Clippy, the MS Office virtual assistant." 
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary="Scout has a very close relationship with ChatGPT." 
          />
        </ListItem>
      </List>
    </AboutContainer>
  );
};

export default About;
