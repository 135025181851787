import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchBar from './SearchBar';
import ExampleSearch from './ExampleSearch';
import useStore from '../state/store';  // Ensure your store is correctly imported
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const HomePage = () => {
  const setTopic = useStore((state) => state.setTopic);
  const clearSearch = useStore((state) => state.clearSearch);
  const navigate = useNavigate();

  const handleSearch = (query) => {
    setTopic(query);
    navigate('/results');
  };

  useEffect(() => {
    clearSearch();
  }, []);

  const exampleTopics = [
    'Garlic is bad for dogs', 
    'The Earth is spherical', 
    'COVID vaccines are safe',
    'The world is getting hotter'
  ];

  return (
    <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        
        <Typography variant="body1" paragraph style={{ textAlign: 'center', fontWeight: '500', maxWidth: '80%' }}>
          Welcome to ScoutBot, home of Scout, your virtual research assistant. Looking to bolster or disprove a claim? Enter an assertion into the search box, and Scout will research it for you.
        </Typography>
        
        <div style={{ width: '80%', marginBottom: '20px', backgroundColor: '#f4f4f4', borderRadius: '5px' }}>
          <SearchBar onSearch={handleSearch} />
        </div>
  
        <Typography variant="h6" style={{ marginBottom: '10px' }}>
          Here are some examples you can try:
        </Typography>
        
        <List style={{ width: '80%' }}>
          {exampleTopics.map((topic, index) => (
            <React.Fragment key={index}>
              <ExampleSearch 
                query={topic} 
                onSearch={handleSearch}
                style={{ 
                  padding: '10px 0',
                  transition: 'background-color 0.3s',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#f4f4f4'
                  }
                }}
              />
              {index < exampleTopics.length - 1 && <Divider style={{ margin: '0 20%' }} />}
            </React.Fragment>
          ))}
        </List>
    </div>
  );
  
};

export default HomePage;
