import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const Loading = ({ message }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      textAlign="center"
    >
      <CircularProgress />
      {message && (
        <Typography variant="body1" style={{ marginTop: '20px' }}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default Loading;

// Example usage:
// <Loading message="Fetching your data..." />
