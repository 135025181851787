// store.js
import { create } from 'zustand';
import axios from 'axios';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

console.warn(process.env.REACT_APP_API_URL);

const useTopicStore = create((set, get) => ({
  data: null,
  error: null,
  loading: false,
  topic: '',
  authorities: [],
  auth_loading: false,
  evidence: [],
  evidence_loading: false,

  setTopic: (term) => set({ topic: term }),

  fetchData: async () => {
    set({ loading: true, error: null });
    try {
      const response = await axios.post(`${REACT_APP_API_URL}/v1/ai/evaluate`, {"topic": get().topic});
      set({ data: response.data, loading: false });
    } catch (error) {
      set({ data: null, error: error.message, loading: false });
    }
  },

  clearSearch: 
    () => set({ 
        data: null,
        error: null,
        loading: false,
        topic: '',
        authorities: [],
        auth_loading: false,
        evidence: [],
        evidence_loading: false,
      }),

  getAuthorities: async () => {
    set({ auth_loading: true, error: null });
    try {
      const response = await axios.post(`${REACT_APP_API_URL}/v1/ai/authorities`, {"topic": get().topic});
      set({ authorities: response.data, auth_loading: false });
    } catch (error) {
      set({ authorities: [], error: error.message, auth_loading: false });
    }
  },

  getEvidence: async () => {
    set({ evidence_loading: true, error: null });
    try {
      const response = await axios.post(`${REACT_APP_API_URL}/v1/ai/cite`, {"topic": get().topic});
      set({ evidence: response.data, evidence_loading: false });
    } catch (error) {
      set({ evidence: [], error: error.message, evidence_loading: false });
    }
  }
}));

export default useTopicStore;
