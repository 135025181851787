import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const ExampleSearch = ({ query, onSearch }) => {
  return (
    <ListItem button onClick={() => onSearch(query)}>
      <ListItemText primary={query} />
      <ListItemIcon>
        <ArrowForwardIosIcon />
      </ListItemIcon>
    </ListItem>
  );
};
export default ExampleSearch;
